import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Container,
  Spinner,
  Table,
  Dropdown,
  Alert,
  Button,
} from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import ExtensionAd from "../Components/ExtensionAd";
import AppMetaDataContext from "../context/AppMetaDataContext";

export default function Library() {
  const metaData = useContext(AppMetaDataContext);

  const [isLoading, setIsLoading] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const socket = useRef(null);

  // Fetch prompts with axios from the server when the component mounts
  useEffect(() => {
    fetchPrompts();
  }, []);

  const fetchPrompts = () => {
    setIsLoading(true);
    axios
      .get("/api/user/prompts")
      .then((response) => {
        console.log("Prompts fetched successfully:", response.data);
        setPrompts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching prompts:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // Create a new WebSocket instance
    socket.current = new WebSocket(process.env.REACT_APP_WEBSOCKETS_URL);

    // Connection opened
    socket.current.addEventListener("open", () => {
      console.log("Connected to WebSocket server");
      // Send handshake message with cookies
      const handshakeMessage = JSON.stringify({ type: "handshake" });
      // socket.current.send(handshakeMessage);
    });

    // Listen for messages
    socket.current.addEventListener("message", (event) => {
      const newMessage = JSON.parse(event.data);
      console.log("New message from WebSocket server:", newMessage);
      fetchPrompts();
    });

    // Connection closed
    socket.current.addEventListener("close", (event) => {
      console.log("WebSocket connection closed");
    });

    // Error handling
    socket.current.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
    });

    // Cleanup on component unmount
    return () => {
      socket.current.close();
    };
  }, []);

  // delete a prompt from the server
  const deletePrompt = (e, promptId) => {
    e.preventDefault();
    axios
      .delete(`/api/user/prompts/${promptId}`)
      .then((response) => {
        console.log("Prompt deleted successfully:", response.data);
        // remove the prompt from the local state
        setPrompts(prompts.filter((prompt) => prompt._id !== promptId));
      })
      .catch((error) => {
        console.error("Error deleting prompt:", error);
      });
  };

  const handleExport = () => {
    const headers = "Name\tPrompt text\tDate created\tDate updated";
    const clipboardData = prompts
      .map(
        (prompt) =>
          `${prompt.name}\t${prompt.text}\t${new Date(
            prompt.date_created
          ).toLocaleString("en-US")}\t${new Date(
            prompt.date_updated
          ).toLocaleString("en-US")}`
      )
      .join("\n");
    navigator.clipboard
      .writeText(`${headers}\n${clipboardData}`)
      .then(() => {
        console.log("Prompts copied to clipboard");
      })
      .catch((err) => {
        console.error("Error copying prompts to clipboard:", err);
      });
  };

  const handleExportTSV = () => {
    const headers = "Name\tPrompt text\tDate created\tDate updated";
    const clipboardData = prompts
      .map(
        (prompt) =>
          `${prompt.name}\t${prompt.text}\t${new Date(
            prompt.date_created
          ).toLocaleString("en-US")}\t${new Date(
            prompt.date_updated
          ).toLocaleString("en-US")}`
      )
      .join("\n");
    const blob = new Blob([`${headers}\n${clipboardData}`], {
      type: "text/tab-separated-values",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "prompts.tsv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    console.log("Prompts exported as TSV");
  };

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <Alert
        variant="light"
        className="d-flex justify-content-between align-items-center"
        style={{ maxWidth: "700px", margin: "0 auto" }}
      >
        <span className="me-2">Got a feature request?</span>
        <Button
          variant="dark"
          size="sm"
          as="a"
          href="https://tally.so/r/w52vAM"
          target="_blank"
          rel="noopener noreferrer"
          className="d-flex align-items-center"
        >
          Give feedback
          <ArrowRight className="ms-2" />
        </Button>
      </Alert>
      <Helmet>
        <title>Statistics - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>
      <div>
        <div className="" style={{ maxWidth: "700px" }}>
          <h1 className="mb-3">Synced prompts</h1>

          <Dropdown className="mb-3">
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Export prompts
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleExport}>
                Copy to clipboard
              </Dropdown.Item>
              <Dropdown.Item onClick={handleExportTSV}>
                Export as TSV
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Display a message if there are no prompts */}
        {prompts.length === 0 && <ExtensionAd />}

        {/* create a table that displays prompt name, text, date_created, date_updated */}
        <Table striped bordered>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Prompt text</th>
              <th scope="col">Date created</th>
              <th scope="col">Date updated</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {prompts.map((prompt, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{prompt.name}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: prompt.text.replace(/\n/g, "<br />"),
                  }}
                ></td>
                <td>
                  {new Date(prompt.date_created).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}{" "}
                  UTC
                </td>
                <td>
                  {new Date(prompt.date_updated).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}{" "}
                  UTC
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={(e) => deletePrompt(e, prompt._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {prompts.length === 0 && (
          <div className="alert alert-warning" role="alert">
            You have no prompts yet. Use the Chrome browser extension to start
            saving and re-using prompts.
          </div>
        )}

        {isLoading && (
          <div className="d-flex justify-content-center mb-5">
            <div className="d-flex flex-column">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading prompts...</span>
              </Spinner>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}
